import React, { useState } from 'react';
import './App.css';

function App() {
  const [lottoNumbers, setLottoNumbers] = useState([]);
  const [superzahl, setSuperzahl] = useState(null);

  const generateLottoNumbers = () => {
    const numbers = [];
    while (numbers.length < 6) {
      const random = Math.floor(Math.random() * 49) + 1;
      if (!numbers.includes(random)) {
        numbers.push(random);
      }
    }
    numbers.sort((a, b) => a - b);

    const superzahlRandom = Math.floor(Math.random() * 10);
    setLottoNumbers(numbers);
    setSuperzahl(superzahlRandom);
  };

  const renderLottoNumbers = lottoNumbers.map((number, index) => (
    <div className="lotto-ball" key={index}>
      {number}
    </div>
  ));

  const superzahlBall = superzahl !== null ? (
    <div className="superzahl-ball">
      {superzahl}
    </div>
  ) : null;

  return (
    <div className="App">
      <header className="App-header">
        <h1>Lottozahlen Generator</h1>
        <p>Generiere deine Lottozahlen für 6 aus 49 und teste dein Glück!</p>
        <div className="lotto-numbers-container">
          <strong>Deine Lottozahlen:</strong>
        </div>
        <div className="lotto-numbers-horizontal">
          {renderLottoNumbers}
        </div>
        <div className="superzahl-container">
          <strong>Deine Superzahl:</strong>
          {superzahlBall}
        </div>
        <button className="generate-button" onClick={generateLottoNumbers}>
          Ziehe meine Glückszahlen!
        </button>
      </header>
    </div>
  );
}

export default App;